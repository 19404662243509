import type { CSSProperties, FC } from 'react';

import { Spinner, type SpinnerProps } from '@volvo/vce-uikit';

export const CenteredSpinner: FC<{ style?: CSSProperties; size?: SpinnerProps['size'] }> = ({
  style,
  size = 'medium',
}) => (
  <section
    style={{
      display: 'grid',
      alignItems: 'center',
      justifyContent: 'center',
      ...style,
    }}
  >
    <Spinner size={size} />
  </section>
);
