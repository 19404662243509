import { type Theme } from '@emotion/react';
import { themes } from '@volvo/vce-uikit';
import type ITheme from '@volvo/vce-uikit/dist/themes/types';

export const theme: Theme = {
  ...themes['volvo-light'],
  colors: {
    ...themes['volvo-light'].colors,
  },
  rightPanel: { minWidth: '450px' },
  map: {
    layout: {
      padding: '10px',
      margin: '10px',
      verticalPadding: '10px 0',
      horizontalPadding: '0 10px',
      gutter: {
        superTiny: '3px',
        tiny: '5px',
        small: '10px',
        medium: '20px',
        large: '40px',
        negative: {
          small: '-10px',
          medium: '-20px',
          large: '-40px',
        },
      },
      height: {
        small: '40px',
        medium: '80px',
        large: '120px',
        fullMinusTopBar: 'calc(100vh - 56px)',
      },
      width: {
        small: '200px',
        medium: '400px',
        large: '800px',
        huge: '1200px',
      },
    },
    design: {
      borderRadius: {
        small: '3px',
        medium: '5px',
        large: '10px',
      },
      dropShadow: {
        small: 'drop-shadow(rgba(0, 0, 0, 0.125) 1px 1px 2px)',
        medium: 'drop-shadow(rgba(0, 0, 0, 0.125) 1px 1px 5px)',
        large: 'drop-shadow(rgba(0, 0, 0, 0.125) 1px 1px 20px)',
      },
      boxShadow: {
        volvoCard: '0px 0.3px 0.5px rgb(0 0 0 / 10%), 0px 2px 4px rgb(0 0 0 / 20%)',
        standard: '1px 1px 2px rgba(0,0,0,0.125)',
      },
      outline:
        'drop-shadow(1px 1px 0px black) drop-shadow(-1px -1px 0 black) drop-shadow(1px -1px 0px black) drop-shadow(-1px 1px 0 black)',
      cardBackgroundColor: 'rgb(255, 255, 255)',
    },
    settings: {
      animationSpeed: {
        short: '0.125s',
        medium: '0.25s',
        long: '1s',
      },
    },
    geofence: {
      borderColor: 'orange',
    },
    speedZone: {
      fillColor: 'yellow',
    },
    typography: {
      fontSize: {
        small: '60%',
        medium: '80%',
        large: '150%',
        huge: '200%',
      },
    },
  },
};

declare module '@emotion/react' {
  export interface Theme extends ITheme {
    rightPanel: { minWidth: string };
    map: {
      layout: {
        padding: string;
        margin: string;
        horizontalPadding: string;
        verticalPadding: string;
        gutter: {
          superTiny: string;
          tiny: string;
          small: string;
          medium: string;
          large: string;
          negative: {
            small: string;
            medium: string;
            large: string;
          };
        };
        height: {
          small: string;
          medium: string;
          large: string;
          fullMinusTopBar: string;
        };
        width: {
          small: string;
          medium: string;
          large: string;
          huge: string;
        };
      };
      design: {
        boxShadow: {
          volvoCard: string;
          standard: string;
        };
        borderRadius: {
          small: string;
          medium: string;
          large: string;
        };
        dropShadow: {
          small: string;
          medium: string;
          large: string;
        };
        outline: string;
        cardBackgroundColor: string;
      };
      settings: {
        animationSpeed: {
          short: string;
          medium: string;
          long: string;
        };
      };
      geofence: {
        borderColor: string;
      };
      speedZone: {
        fillColor: string;
      };
      typography: {
        fontSize: {
          small: string;
          medium: string;
          large: string;
          huge: string;
        };
      };
    };
  }
}
